import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../Assets/logo.png";

const Navbar = () => {
  const theme = useTheme();
  const location = useLocation(); // Get current route
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState('rgba(255, 255, 255, 0.2)');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBackground('rgba(255, 255, 255, 1)');
      } else {
        setNavbarBackground('rgba(255, 255, 255, 0.2)');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navLinks = [
    { text: 'Home', to: '/' },
    { text: 'Agricultural Services', to: '/agri-services' },
    { text: 'Surveillance', to: '/surveillance' },
    { text: 'Products', to: '/products' },
    // { text: 'Training', to: '/cource' },
    { text: 'About Us', to: '/about' },
    { text: 'Contact', to: '/contact' },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: navbarBackground,
        transition: 'background-color 0.3s ease-in-out',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <img src={logo} alt="Logo" style={{ height: '35px' }} />
          </Link>
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton color="primary" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          {navLinks.map((link, index) => (
            <Button
              key={index}
              component={Link}
              to={link.to}
              sx={{
                color: theme.palette.text.secondary,
                borderBottom: location.pathname === link.to ? `2px solid ${theme.palette.primary.main}` : 'none',
                borderRadius: 0,
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease',
                  color: theme.palette.primary.main,
                },
              }}
            >
              {link.text}
            </Button>
          ))}
        </Box>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250, height: '100%' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Box sx={{ textAlign: 'center', py: 2 }}>
              <img src={logo} alt="Logo" style={{ height: '35px' }} />
            </Box>
            <List>
              {navLinks.map((link, index) => (
                <ListItem
                  button
                  component={Link}
                  to={link.to}
                  key={index}
                  sx={{
                    color: theme.palette.primary.main,
                    borderBottom: location.pathname === link.to ? `2px solid ${theme.palette.primary.main}` : 'none',
                    '&:hover': {
                      color: theme.palette.primary.light,
                    },
                    textAlign: 'center',
                  }}
                >
                  <ListItemText primary={link.text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
