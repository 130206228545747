import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");

  // Handling Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(""); // Clear error on input change
  };

  // Form Validation
  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.contactNumber || !formData.message) {
      setError("All fields are required!");
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      setError("Enter a valid email address!");
      return false;
    }
    if (!/^\d{10}$/.test(formData.contactNumber)) {
      setError("Enter a valid 10-digit contact number!");
      return false;
    }
    return true;
  };

  // Handling Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const response = await fetch("https://dsiconenterprises-api.vercel.app/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage("Message sent successfully!");
        setFormData({ name: "", email: "", contactNumber: "", message: "" });
      } else {
        setResponseMessage(data.error || "Something went wrong.");
      }
    } catch (error) {
      setResponseMessage("Failed to send message. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ py: 6, backgroundColor: "#f9f9f9", paddingTop: "100px" }}>
      <Container>
        {/* Header Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary">
          Contact Us
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          We'd love to hear from you! Fill out the form below or reach out using the contact details.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom>
                Send Us a Message
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                {error && (
                  <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                    {error}
                  </Typography>
                )}

                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  color="primary"
                  InputProps={{
                  style: { color: "black" },
                  }}  
                  required
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  type="email"
                  color="primary"
                  InputProps={{
                  style: { color: "black" },
                  }}
                />
                <TextField
                  label="Contact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  type="tel"
                  color="primary"
                  InputProps={{
                  style: { color: "black" },
                  }}
                />
                  <TextField
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    color="primary"
                    InputProps={{
                    style: { color: "black" },
                    }}
                  />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  fullWidth
                >
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
              </Box>

              {responseMessage && (
                <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                  {responseMessage}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                Contact Information
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                <strong>Merchant Legal Entity Name: SACHIN CHAUDHARY</strong>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Registered Address:</strong> H.NO-163, VILLAGE-KUNWARPUR, TEHSIL-SHIKARPUR, POST-PAHASU, PAHASU, UTTAR PRADESH, PIN: 203396
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Operational Address:</strong> H.NO-163, VILLAGE-KUNWARPUR, TEHSIL-SHIKARPUR, POST-PAHASU, PAHASU, UTTAR PRADESH, PIN: 203396
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Telephone No:</strong> 8000606017
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Email:</strong> dsiconentreprises@gmail.com
              </Typography>

              <Typography variant="h6" sx={{ mt: 4 }}>
                Follow Us
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                <Tooltip title="Facebook" arrow>
                  <IconButton href="https://facebook.com" target="_blank" color="primary">
                    <FacebookIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Twitter" arrow>
                  <IconButton href="https://twitter.com" target="_blank" color="primary">
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="LinkedIn" arrow>
                  <IconButton href="https://linkedin.com" target="_blank" color="primary">
                    <LinkedInIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;