// src/components/AgriServices/AgriServices.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

// Replace with actual image imports or URLs
import cropMonitoringImage from '../../Assets/crop-monitoring.jpg';
import soilAnalysisImage from '../../Assets/soil-analysis.jpg';
import precisionSprayingImage from '../../Assets/precision-spraying.jpg';
import fieldMappingImage from '../../Assets/field-mapping.jpg';
import irrigationManagementImage from '../../Assets/irrigation-management.jpg';
import yieldEstimationImage from '../../Assets/yield-estimation.jpg';

const AgriServices = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };
  return (
    <Box sx={{ py: 6, background: 'linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%)', paddingTop:'100px'}}>
      <Container>
        {/* Header Section */}
        <Fade top>
          <Typography variant="h3" align="center" color="primary" gutterBottom sx={{
            fontWeight: 'bold',
            mb: 4,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '60px',
              height: '3px',
              backgroundColor: 'primary.main',
              borderRadius: '2px'
            }
          }}>
            Agricultural Drone Services
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph sx={{ mb: 6 }}>
            Revolutionizing agriculture with precision drone technology across multiple services.
          </Typography>
        </Fade>

        {/* Service Details Section */}
        
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Crop Monitoring and Health Assessment */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={cropMonitoringImage}
                  alt="Crop Monitoring and Health Assessment"
                  sx={{
                    height: 250,
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    }
                  }}
                />
                <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Crop Monitoring and Health Assessment
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Our drones capture multispectral images to assess crop health, identify stress areas, and detect diseases early for timely intervention.
                  </Typography>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleContactClick}
                    sx={{
                      mt: 2,
                      position: 'relative',
                      overflow: 'hidden',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                        transform: 'translateX(-100%)',
                      },
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                        '&:before': {
                          transform: 'translateX(100%)',
                          transition: 'transform 0.8s',
                        }
                      }
                    }}
                  >
                    Contact Us
                  </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>

          {/* Soil Analysis */}
          <Grid item xs={12} md={6}>
            <Zoom>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
              }
            }}>
              <CardMedia
                component="img"
                image={soilAnalysisImage}
                alt="Soil Analysis"
                sx={{
                  height: 250,
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
              <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="primary" gutterBottom sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontSize: '1.5rem'
                }}>
                  Soil Analysis
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Conduct aerial surveys to evaluate soil conditions, moisture levels, and nutrient content, assisting in efficient land and crop management.
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
          </Zoom>
          </Grid>

          {/* Precision Spraying */}
          <Grid item xs={12} md={6}>
            <Zoom>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
              }
            }}>
              <CardMedia
                component="img"
                image={precisionSprayingImage}
                alt="Precision Spraying"
                sx={{
                  height: 250,
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
              <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="primary" gutterBottom sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontSize: '1.5rem'
                }}>
                  Precision Spraying
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Targeted application of fertilizers and pesticides to minimize waste, maximize crop quality, and reduce environmental impact.
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
            </Zoom>
          </Grid>

          {/* Field Mapping and Surveying */}
          <Grid item xs={12} md={6}>
            <Zoom>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
              }
            }}>
              <CardMedia
                component="img"
                image={fieldMappingImage}
                alt="Field Mapping and Surveying"
                sx={{
                  height: 250,
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
              <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="primary" gutterBottom sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontSize: '1.5rem'
                }}>
                  Field Mapping and Surveying
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Create detailed, high-resolution maps of fields to assist in planning, planting, and monitoring, providing valuable insights for land management.
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
            </Zoom>
          </Grid>

          {/* Irrigation Management */}
          <Grid item xs={12} md={6}>
            <Zoom>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
              }
            }}>
              <CardMedia
                component="img"
                image={irrigationManagementImage}
                alt="Irrigation Management"
                sx={{
                  height: 250,
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
              <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="primary" gutterBottom sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontSize: '1.5rem'
                }}>
                  Irrigation Management
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Monitor water distribution and identify areas requiring irrigation adjustments to ensure optimal water usage across the farm.
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
            </Zoom>
          </Grid>

          {/* Yield Estimation */}
          <Grid item xs={12} md={6}>
            <Zoom>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
              }
            }}>
              <CardMedia
                component="img"
                image={yieldEstimationImage}
                alt="Yield Estimation"
                sx={{
                  height: 250,
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
              <CardContent sx={{ textAlign: 'center', flexGrow: 1, p: 3 }}>
                <Typography variant="h5" color="primary" gutterBottom sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontSize: '1.5rem'
                }}>
                  Yield Estimation
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Use drone data to estimate crop yields, providing insights for future planting and market strategies, enhancing productivity and profits.
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
            </Zoom>
          </Grid>
        </Grid>

        {/* Call to Action Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Transform Your Farming Practices
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Contact us today to learn more about our agricultural drone services and how we can assist you in achieving higher efficiency and productivity.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Get in Touch
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AgriServices;
