import React, { useState } from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import product images
import crockeryImage from '../../Assets/crockery.svg';
import jaggeryImage from '../../Assets/jaggery-for-agriculture-1000x100.jpg';
import woodPelletImage from '../../Assets/Wood-Pellet-Leaf-1-1000x667.jpg';
import biomassPelletImage from '../../Assets/biomass-pellets-1000x1000.jpg';

const Products = () => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleContactClick = () => {
    navigate('/contact');
  };

  const products = [
    {
      title: 'Crockery',
      image: crockeryImage,
      description: 'High-quality kitchenware and dining essentials crafted with precision and durability in mind.',
      features: [
        'Food-grade materials safe for agricultural produce storage.',
        'UV-resistant and weather-proof containers.',
        'Ideal for storing harvested crops and agricultural products.',
        'Stackable design for efficient storage.'
      ],
      applications: [
        'Post-harvest storage solutions.',
        'Agricultural product packaging.',
        'Farm-to-table presentation.',
        'Bulk commodity storage'
      ],
      benefits: [
        'Extended shelf life for agricultural products.',
        'Reduced post-harvest losses.',
        'Easy cleaning and maintenance.',
        'Cost-effective storage solution.'
      ],
      specifications: [
        'Material: Food-grade polymer.',
        'Temperature resistance: -20°C to 120°C.',
        'UV protection: 99%.',
        'Stackable up to 8 units.'
      ]
    },
    {
      title: 'Jaggery',
      image: jaggeryImage,
      description: 'Natural and organic jaggery made from pure sugarcane juice, rich in minerals and nutrients.',
      features: [
        'Made from organically grown sugarcane.',
        'Chemical-free processing methods.',
        'Rich in iron and mineral content.',
        'Perfect for agricultural community needs.'
      ],
      applications: [
        'Natural sweetener in food processing.',
        'Traditional medicine preparations.',
        'Energy supplement for farm workers.',
        'Value-added agricultural product.'
      ],
      benefits: [
        'Higher nutritional value than refined sugar.',
        'Supports local farming communities.',
        'Long shelf life without preservatives.',
        'Sustainable production process.'
      ],
      specifications: [
        'Source: 100% organic sugarcane.',
        'Processing: Traditional method.',
        'Shelf life: 12 months.',
        'Packaging: Eco-friendly materials.'
      ]
    },
    {
      title: 'Wood Pellet',
      image: woodPelletImage,
      description: 'Sustainable wood pellets for heating and energy production, made from compressed sawdust and wood shavings.',
      features: [
        'Eco-friendly alternative for agricultural heating.',
        'High energy efficiency for greenhouse operations.',
        'Made from sustainable forest waste.',
        'Low ash content for cleaner burning.'
      ],
      applications: [
        'Greenhouse heating systems.',
        'Agricultural processing facilities.',
        'Biomass power generation.',
        'Industrial drying operations.'
      ],
      benefits: [
        'Reduced carbon footprint.',
        'Lower heating costs.',
        'Consistent heat output.',
        'Minimal storage space required.'
      ],
      specifications: [
        'Diameter: 6-8mm.',
        'Moisture content: <10%.',
        'Ash content: <0.7%.',
        'Caloric value: >4200 kcal/kg.'
      ]
    },
    {
      title: 'Biomass Pellet',
      image: biomassPelletImage,
      description: 'Eco-friendly biomass pellets produced from agricultural residues, perfect for industrial and residential use.',
      features: [
        'Created from agricultural waste materials.',
        'Supports sustainable farming practices.',
        'High calorific value for efficient energy.',
        'Reduces agricultural waste disposal issues'
      ],
      applications: [
        'Industrial boilers and furnaces.',
        'Combined heat and power plants.',
        'Agricultural waste management.',
        'Residential heating solutions'
      ],
      benefits: [
        'Waste to energy conversion.',
        'Additional income for farmers.',
        'Reduced environmental impact.',
        'Year-round availability'
      ],
      specifications: [
        'Raw material: Agricultural residues.',
        'Density: >600 kg/m³.',
        'Moisture content: <12%.',
        'Length: 14-40mm'
      ]
    },
  ];

  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9', paddingTop: '100px', minHeight: '100vh' }}>
      <Container>
        {/* Header Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary" sx={{
          fontWeight: 700,
          mb: 3,
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          '&::after': {
            content: '""',
            display: 'block',
            width: '60px',
            height: '4px',
            backgroundColor: 'primary.main',
            margin: '15px auto',
            borderRadius: '2px'
          }
        }}>
          Our Products
        </Typography>
        <Typography variant="h6" align="center" paragraph sx={{
          color: 'text.secondary',
          maxWidth: '800px',
          mx: 'auto',
          mb: 5,
          fontSize: '1.2rem'
        }}>
          Quality products for your everyday needs
        </Typography>

        {/* Products Grid */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {products.map((product, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  position: 'relative',
                  transition: 'all 0.3s ease-in-out',
                  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                  borderRadius: '15px',
                  border: '1px solid rgba(255,255,255,0.18)',
                  backdropFilter: 'blur(10px)',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 20px 30px rgba(0,0,0,0.1)',
                    background: 'linear-gradient(145deg, #f0f0f0, #ffffff)'
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={product.image}
                  alt={product.title}
                  sx={{ height: 300, objectFit: 'contain' }}
                />
                <CardContent sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  flex: 1, 
                  position: 'relative', 
                  pb: 8,
                  '&:hover': {
                    '& .read-more-button': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 5px 15px rgba(0,0,0,0.2)'
                    }
                  }
                }}>
                  <Typography variant="h5" component="div" gutterBottom align="center" sx={{ 
                    fontSize: '2.2rem', 
                    fontWeight: 700, 
                    color: 'primary.main',
                    mb: 3,
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    letterSpacing: '0.5px'
                  }}>
                    {product.title}
                  </Typography>
                  <Typography variant="body2" paragraph align="center" sx={{
                    color: 'text.secondary',
                    fontSize: '1.1rem',
                    lineHeight: 1.6,
                    maxWidth: '90%',
                    mx: 'auto'
                  }}>
                    {product.description}
                  </Typography>
                  <Box sx={{ mt: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                      className="read-more-button"
                      variant="contained"
                      color="primary"
                      onClick={() => handleProductClick(product)}
                      sx={{
                        opacity: 1,
                        transition: 'all 0.3s ease-in-out',
                        borderRadius: '25px',
                        padding: '10px 25px',
                        fontWeight: 600,
                        textTransform: 'none',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: '0 8px 15px rgba(0,0,0,0.2)'
                        }
                      }}
                    >
                      Read More
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Additional Information */}
        <Box sx={{ mt: 8, textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.8)', py: 5, px: 3, borderRadius: '20px', boxShadow: '0 10px 30px rgba(0,0,0,0.05)' }}>
          <Typography variant="h4" gutterBottom>
            Why Choose Our Products?
          </Typography>
          <Typography variant="body1" color='black' paragraph>
            We take pride in offering high-quality products that meet international standards. Our commitment to sustainability
            and customer satisfaction sets us apart in the market.
          </Typography>
        </Box>
      </Container>

      {/* Product Details Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        TransitionProps={{
          timeout: 500
        }}
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            overflowY: 'auto',
            borderRadius: '20px',
            background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
            boxShadow: '0 25px 50px -12px rgba(0,0,0,0.25)',
            '&:hover': {
              boxShadow: '0 25px 50px -6px rgba(0,0,0,0.3)'
            }
          }
        }}
      >
        {selectedProduct && (
          <>
            <DialogTitle sx={{
              background: 'linear-gradient(145deg, #f8f9fa, #ffffff)',
              borderBottom: '1px solid rgba(0,0,0,0.1)',
              py: 3
            }}>
              <Typography 
                variant="h4" 
                component="div" 
                align="center" 
                color="primary.main"
                sx={{
                  fontWeight: 700,
                  letterSpacing: '0.5px',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                }}
              >
                {selectedProduct.title}
              </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{
              background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
              p: 4
            }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                gap: 4,
                '& > *': {
                  transform: 'translateY(0)',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }
              }}>
                <Box sx={{ width: '100%', maxWidth: 500 }}>
                  <CardMedia
                    component="img"
                    image={selectedProduct.image}
                    alt={selectedProduct.title}
                    sx={{ height: 300, objectFit: 'contain' }}
                  />
                </Box>
                
                <Typography variant="body1" color='text.secondary'  paragraph align="center">
                  {selectedProduct.description}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                  p: 3, 
                  bgcolor: 'rgba(255,255,255,0.9)', 
                  borderRadius: '15px',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.05)',
                  border: '1px solid rgba(0,0,0,0.05)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 12px 25px rgba(0,0,0,0.1)',
                    transform: 'translateY(-5px)'
                  }
                }}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        Key Features:
                      </Typography>
                      <ul style={{ marginLeft: '20px', color: 'black' }}>
                        {selectedProduct.features.map((feature, idx) => (
                          <li key={idx}>
                            <Typography variant="body1" color="black">{feature}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                  p: 3, 
                  bgcolor: 'rgba(255,255,255,0.9)', 
                  borderRadius: '15px',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.05)',
                  border: '1px solid rgba(0,0,0,0.05)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 12px 25px rgba(0,0,0,0.1)',
                    transform: 'translateY(-5px)'
                  }
                }}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        Applications:
                      </Typography>
                      <ul style={{ marginLeft: '20px', color: 'black' }}>
                        {selectedProduct.applications.map((application, idx) => (
                          <li key={idx}>
                            <Typography variant="body1" color="black">{application}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                  p: 3, 
                  bgcolor: 'rgba(255,255,255,0.9)', 
                  borderRadius: '15px',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.05)',
                  border: '1px solid rgba(0,0,0,0.05)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 12px 25px rgba(0,0,0,0.1)',
                    transform: 'translateY(-5px)'
                  }
                }}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        Benefits:
                      </Typography>
                      <ul style={{ marginLeft: '20px', color: 'black' }}>
                        {selectedProduct.benefits.map((benefit, idx) => (
                          <li key={idx}>
                            <Typography variant="body1" color="black">{benefit}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                  p: 3, 
                  bgcolor: 'rgba(255,255,255,0.9)', 
                  borderRadius: '15px',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.05)',
                  border: '1px solid rgba(0,0,0,0.05)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 12px 25px rgba(0,0,0,0.1)',
                    transform: 'translateY(-5px)'
                  }
                }}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        Technical Specifications:
                      </Typography>
                      <ul style={{ marginLeft: '20px', color: 'black' }}>
                        {selectedProduct.specifications.map((spec, idx) => (
                          <li key={idx}>
                            <Typography variant="body1" color="black">{spec}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions sx={{ 
              p: 3, 
              justifyContent: 'center', 
              gap: 3,
              background: 'linear-gradient(145deg, #f8f9fa, #ffffff)',
              borderTop: '1px solid rgba(0,0,0,0.1)'
            }}>
              <Button onClick={handleCloseDialog} variant="outlined" color="primary">
                Close
              </Button>
              <Button onClick={handleContactClick} variant="contained" color="primary">
                Contact for Pricing
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Products;