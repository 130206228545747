import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" style={{paddingTop:"75px", paddingBottom:"75px"}}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom color="textSecondary" fontWeight={600}>
          Terms & Conditions
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Last updated on 30-01-2025 21:59:03
        </Typography>

        <Box mt={2} color={{ color: "black" }}>
          <Typography variant="body1" paragraph>
            These Terms and Conditions, along with the Privacy Policy or other terms
            (“Terms”) constitute a binding agreement by and between{" "}
            <strong>SACHIN CHAUDHARY</strong> (“Website Owner” or “we” or “us” or “our”)
            and you (“you” or “your”) and relate to your use of our website, goods (as
            applicable), or services (as applicable) (collectively, “Services”).
          </Typography>

          <Typography variant="body1" paragraph>
            By using our website and availing the Services, you agree that you have
            read and accepted these Terms (including the Privacy Policy). We reserve
            the right to modify these Terms at any time and without assigning any
            reason. It is your responsibility to periodically review these Terms to
            stay informed of updates.
          </Typography>

          <Typography variant="h6" mt={2}>
            Terms of Use:
          </Typography>
          <ul>
            <li>
              You agree to provide true, accurate, and complete information during and
              after registration and are responsible for all acts done through your
              registered account.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee as to
              the accuracy, timeliness, performance, completeness, or suitability of
              the information and materials offered on this website.
            </li>
            <li>
              Your use of our Services and the website is solely at your own risk and
              discretion. You must independently assess and ensure that the Services
              meet your requirements.
            </li>
            <li>
              The contents of the Website and the Services are proprietary to Us, and
              you will not have any authority to claim any intellectual property rights
              in its contents.
            </li>
            <li>
              Unauthorized use of the Website or Services may lead to legal action as
              per these Terms or applicable laws.
            </li>
            <li>You agree to pay the charges associated with availing the Services.</li>
            <li>
              You agree not to use the website or Services for any unlawful or illegal
              purpose.
            </li>
            <li>
              The website may contain links to third-party websites. Upon accessing
              these links, you will be governed by their respective terms and privacy
              policies.
            </li>
            <li>
              Initiating a transaction for availing Services constitutes a legally
              binding and enforceable contract between you and us.
            </li>
            <li>
              Refunds are available if we are unable to provide the Service. Refund
              requests must be made within the stipulated time, as outlined in our
              policies.
            </li>
            <li>
              Neither party shall be liable for failure to perform an obligation under
              these Terms if performance is prevented or delayed by a force majeure
              event.
            </li>
            <li>
              These Terms shall be governed by and construed in accordance with the
              laws of India.
            </li>
            <li>
              All disputes arising out of or in connection with these Terms shall be
              subject to the exclusive jurisdiction of the courts in{" "}
              <strong>PAHASU (DEHAT), UTTAR PRADESH</strong>.
            </li>
            <li>
              For any concerns or communications regarding these Terms, you must use
              the contact information provided on this website.
            </li>
          </ul>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
