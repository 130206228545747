import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css"
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LandingPage from './components/Home/LandingPage';
import AgriServices from './components/AgriServices/AgriServices';
import Surveillance from './components/Surveillance/Surveillance';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import ScrollToTop from './components/ScrollToTop';
import TermsAndConditions from './pages/TermsAndConditions ';
import CancellationRefundPolicy from './pages/CancellationRefundPolicy';
import DroneTraining from './pages/DroneTraining';
import Products from './components/Products/Products';

const App = () => (
  <Router>
    <div className="app">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/agri-services" element={<AgriServices />} />
        <Route path="/surveillance" element={<Surveillance />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/cancellation-refund" element={<CancellationRefundPolicy />} />
        <Route path='/cource' element={<DroneTraining/>}/>
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
