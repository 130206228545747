import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";

const CancellationRefundPolicy = () => {
  return (
    <Container maxWidth="md" style={{paddingTop:"75px", paddingBottom:"75px"}}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom color="textSecondary" fontWeight={600}>
          Cancellation & Refund Policy
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Last updated on 30-01-2025 22:01:52
        </Typography>

        <Box mt={2} color="BLACK">
          <Typography variant="body1" paragraph>
            <strong>SACHIN CHAUDHARY</strong> believes in helping its customers as far as possible and has, 
            therefore, a liberal cancellation policy. Under this policy:
          </Typography>

          <Typography variant="h6" mt={2}>
            Cancellation Policy:
          </Typography>
          <ul>
            <li>
              Cancellations will be considered only if the request is made immediately after placing the order. 
              However, the cancellation request may not be entertained if the orders have already been processed 
              by vendors/merchants for shipping.
            </li>
            <li>
              Cancellation requests for perishable items like flowers and eatables will not be accepted. 
              However, refund/replacement can be considered if the customer establishes that the product quality 
              delivered was not satisfactory.
            </li>
          </ul>

          <Typography variant="h6" mt={2}>
            Refund Policy:
          </Typography>
          <ul>
            <li>
              If you receive damaged or defective items, please report the issue to our Customer Service team 
              on the same day of receipt. The claim will be verified by the merchant before approval.
            </li>
            <li>
              If the product does not match what was displayed on the site or does not meet expectations, 
              it must be reported to our customer service within the same day of receiving the product. 
              Our Customer Service Team will review the complaint and take appropriate action.
            </li>
            <li>
              For products covered under the manufacturer’s warranty, complaints should be directed to the manufacturer.
            </li>
            <li>
              If a refund is approved by <strong>SACHIN CHAUDHARY</strong>, the refund process will take between <strong>16-30 days</strong> 
              for completion.
            </li>
          </ul>
        </Box>
      </Paper>
    </Container>
  );
};

export default CancellationRefundPolicy;
