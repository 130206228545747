// src/pages/DroneTraining.js
import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const courses = [
  {
    id: 1,
    name: "DGCA Certified Drone Pilot Training",
    price: "₹85,000 + GST",
    duration: "10 Days",
    description: "Comprehensive training with DGCA certification for drone pilots.",
  },
  {
    id: 2,
    name: "Advanced Drone Mapping & Surveying",
    price: "₹50,000 + GST",
    duration: "7 Days",
    description: "Hands-on training for aerial mapping and photogrammetry using drones.",
  },
  {
    id: 3,
    name: "Agricultural Drone Spraying Course",
    price: "₹40,000 + GST",
    duration: "5 Days",
    description: "Learn precision drone spraying techniques for modern farming.",
  },
  {
    id: 4,
    name: "Drone-Based Security & Surveillance",
    price: "₹60,000 + GST",
    duration: "8 Days",
    description: "Training on UAV security, night surveillance, and live monitoring.",
  },
  {
    id: 5,
    name: "FPV Drone Racing & Acro Mode Training",
    price: "₹30,000 + GST",
    duration: "4 Days",
    description: "Master FPV drone flying techniques, racing strategies, and freestyle.",
  },
  {
    id: 6,
    name: "Drone Repair & Maintenance Workshop",
    price: "₹25,000 + GST",
    duration: "3 Days",
    description: "Understand drone components, troubleshooting, and maintenance.",
  },
];

const DroneTraining = () => {
  return (
    <Container sx={{ mt: 5,paddingTop:"50px",paddingBottom:"50px" }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center' }}>
        Drone Training Courses
      </Typography>

      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <Card sx={{ height: '100%', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold',color: 'text.secondary' }}>{course.name}</Typography>
                <Typography sx={{ mt: 1, color: 'text.secondary' }}>⏳ Duration: {course.duration}</Typography>
                <Typography sx={{ mt: 1, color: 'text.secondary' }}>💰 Price: {course.price}</Typography>
                <Typography sx={{ mt: 1,color: 'text.secondary' }}>{course.description}</Typography>
                <Button 
                  component={Link} 
                  to={`/course/${course.id}`} 
                  variant="contained" 
                  sx={{ mt: 2, width: '100%' }}
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DroneTraining;
