// src/components/Surveillance/Surveillance.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useNavigate } from 'react-router-dom';
import areaMonitoringImage from '../../Assets/area-monitoring.jpg';
import wildlifeTrackingImage from '../../Assets/wildlife-tracking.jpg';
import infrastructureInspectionImage from '../../Assets/infrastructure-inspection.jpg';
import emergencyResponseImage from '../../Assets/emergency-response.jpg';
import pipelineInspectionImage from '../../Assets/pipeline-inspection.jpg';
import searchAndRescueImage from '../../Assets/search-and-rescue.jpg';

const Surveillance = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };
  return (
    <Box sx={{ py: 6, background: 'linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%)', paddingTop: '100px' }}>
      <Container>
        {/* Header Section */}
        <Fade top>
          <Typography variant="h3" gutterBottom align="center" color="primary" sx={{
            fontWeight: 'bold',
            mb: 4,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '60px',
              height: '3px',
              backgroundColor: 'primary.main',
              borderRadius: '2px'
            }
          }}>
            Drone Surveillance Services
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph sx={{ mb: 6 }}>
            Advanced surveillance solutions for diverse applications, ensuring safety and efficiency.
          </Typography>
        </Fade>

        {/* Service Details Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Area Monitoring */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={areaMonitoringImage}
                  alt="Area Monitoring"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Area Monitoring
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Monitor large areas with real-time aerial views, ideal for managing events, overseeing construction sites, and enhancing perimeter security.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>

          {/* Wildlife Tracking */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={wildlifeTrackingImage}
                  alt="Wildlife Tracking"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Wildlife Tracking
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Safely observe and track wildlife without disturbing habitats, supporting conservation efforts and animal behavior studies.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>

          {/* Infrastructure Inspection */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={infrastructureInspectionImage}
                  alt="Infrastructure Inspection"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Infrastructure Inspection
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Conduct thorough inspections of bridges, buildings, and power lines with drone technology, identifying potential hazards with precision.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>

          {/* Emergency Response */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={emergencyResponseImage}
                  alt="Emergency Response"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Emergency Response
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Drones provide rapid aerial assessments during natural disasters or accidents, aiding emergency responders in coordinating efforts.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>

          {/* Border Patrol
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={borderPatrolImage} // Use imported image or URL
                alt="Border Patrol"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Border Patrol
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Enhance border security with continuous aerial monitoring, detecting unauthorized activities over expansive and remote areas.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
 */}

            {/* Pipeline Inspection Card */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={pipelineInspectionImage}
                  alt="Pipeline Inspection"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Pipeline Inspection
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    High-resolution drone inspections to monitor and maintain the integrity of pipelines, detecting leaks and damages efficiently.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>
          
          {/* Search and Rescue */}
          <Grid item xs={12} md={6}>
            <Zoom>
              <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                background: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0 10px 40px 0 rgba(31, 38, 135, 0.25)'
                }
              }}>
                <CardMedia
                  component="img"
                  image={searchAndRescueImage}
                  alt="Search and Rescue"
                  sx={{
                    height: 250,
                    display: "flex",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'

                    }
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3, display:'flex',flexDirection:'column',alignItems:'center' }}>
                  <Typography variant="h5" color="primary" gutterBottom sx={{
                    fontWeight: 600,
                    mb: 2,
                    fontSize: '1.5rem'
                  }}>
                    Search and Rescue
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Equipped with thermal cameras, drones assist in locating missing persons in challenging terrains and conditions.
                  </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleContactClick}
                  sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                      transform: 'translateX(-100%)',
                    },
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                      '&:before': {
                        transform: 'translateX(100%)',
                        transition: 'transform 0.8s',
                      }
                    }
                  }}
                >
                  Contact Us
                </Button>
                </CardContent>
              </Card>
            </Zoom>
          </Grid>
        </Grid>

        {/* Call to Action Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Secure Your Environment with Our Drone Surveillance
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Contact us today to learn more about our advanced drone surveillance solutions tailored to your security needs.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Get in Touch
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Surveillance;